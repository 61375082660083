import { useEffect, useState } from 'react';
import powertower from '../../images/powertower.jpeg'
import '../home/Home.css'
import './Tower.css'

const Tower = () => {
    const [date, setDate] = useState('')
    useEffect(() => {
        const d = new Date();
        d.setMonth(d.getMonth() - 1);
        d.setDate(d.getDate() - 20)
        setDate(d.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) )
    }, [])

    return (
        <div className="tower-container">
            <div className="home-text-container">
                <a 
                    href="https://www.amazon.com/gp/product/B002Y2SUU4/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B002Y2SUU4&amp;linkId=e1cdc6ed9c1040163c3dae63a33d71a0"
                    target="_blank"
                    className="outside-link"
                    rel="noopener noreferrer"
                >
                    <img src={powertower} className="main-image tower-image" alt="power tower" />
                </a>
                <p className="last-updated">Last updated on {date}</p>
                <div className="home-header-container">
                    <div className="home-divider">|</div>
                    <h1 className="home-header">Best Power Tower For Upper Body Stength</h1>
                </div>
                <p className="paragraph">
                    The Best Power Tower for Small Spaces is the
                    <a 
                        href="https://www.amazon.com/gp/product/B002Y2SUU4/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B002Y2SUU4&amp;linkId=e1cdc6ed9c1040163c3dae63a33d71a0"
                        target="_blank"
                        className="outside-link"
                        rel="noopener noreferrer"
                    >
                        Stamina 1690 Power Tower
                    </a>
                    . Having this system in my apartment has enabled me to
                    gradually build the muscle strength necessary for chin-ups
                    and pull-ups workouts. It was easy to assemble,
                    and has proven over the past year to be stable and well built.
                    It easily fits in any apartment room with an 8 foot ceiling.
                </p>
                <p className="paragraph">The Stamina 1690 Power Tower is a versatile and effective piece of fitness equipment that provides a full-body workout. This tower is designed to target multiple muscle groups, including your arms, chest, back, and core, making it an ideal choice for anyone looking to improve their overall fitness and strength.</p>
                <p className="paragraph">One of the most impressive features of this power tower is its sturdy construction. Made from durable steel, the tower is designed to withstand even the most intense workouts, providing you with a stable and safe platform for your exercises. The tower also has a weight capacity of 250 pounds, making it suitable for users of all sizes and fitness levels.</p>
                <p className="paragraph">Another great feature of the Stamina 1690 Power Tower is its variety of exercises. The tower includes multiple grip positions for pull-ups and chin-ups, allowing you to target different muscle groups and vary your workout. Additionally, the tower includes a dip station, allowing you to work your chest, triceps, and shoulders, and a push-up station, allowing you to work your chest, shoulders, and arms.</p>
                <p className="paragraph">The tower also features a comfortable and adjustable backrest, allowing you to perform vertical knee raises and leg raises to target your core muscles. The backrest is padded and provides a comfortable and stable platform for your exercises.</p>
                <p className="paragraph">Other features of the Stamina 1690 Power Tower include its compact design and easy assembly. The tower can be easily assembled in your home gym or workout area, and its compact design allows it to fit into even small spaces.</p>
                <p className="paragraph">
                    Overall, the Stamina 1690 Power Tower is an excellent choice for anyone looking for a versatile and effective piece of fitness equipment. With its sturdy construction, variety of exercises, and comfortable backrest, this tower is sure to help you achieve your fitness goals and improve your overall strength and endurance.
                    <a 
                        href="https://www.amazon.com/gp/product/B002Y2SUU4/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B002Y2SUU4&amp;linkId=e1cdc6ed9c1040163c3dae63a33d71a0"
                        target="_blank"
                        className="outside-link"
                        rel="noopener noreferrer"
                    >
                        $89, Amazon
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Tower;
