import main from '../../images/main.jpeg'
import { useEffect, useState } from 'react';
import './Home.css';

const Home = () => {
    const [date, setDate] = useState('')
    useEffect(() => {
        const d = new Date();
        d.setDate(d.getDate() - 3)
        setDate(d.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) )
    }, [])

    return (
        <div className="home-container">
            <img src={main} className="home-image" alt="apartment gym" />
            <div className="home-text-container">
                <p className="last-updated">Last updated on {date}</p>
                <div className="home-header-container">
                    <div className="home-divider">|</div>
                    <h1 className="home-header">Home Is Where The Gym Is</h1>
                </div>
                <p className="paragraph">Welcome to Apartment Workouts, your ultimate guide to staying fit and healthy in the comfort of your own home. Our website is dedicated to providing you with expert reviews on the best equipment for apartment workouts and sharing workout advice to help you achieve your fitness goals.</p>
                <p className="paragraph">We understand that many people live in apartments and don't have access to a gym or outdoor space for exercise. That's why we have created this website to help you make the most out of your apartment workouts. Our team of fitness experts has reviewed the best workout equipment for small spaces, so you can find the right tools to fit your home and workout style.</p>
                <p className="paragraph">Our website features articles, videos, and workout routines that cater to all fitness levels, from beginners to experienced gym-goers. We believe that anyone can achieve their fitness goals, regardless of their living situation or time constraints. Our team of trainers provides advice and guidance on the best apartment workouts for weight loss, muscle gain, and overall health.</p>
                <p className="paragraph">We understand that apartment workouts can be challenging, and finding the right equipment can be overwhelming. That's why we have created detailed reviews of the best equipment for apartment workouts, including exercise bikes, adjustable dumbbells, and compact treadmills, among others. We also provide guidance on how to use each piece of equipment effectively for optimal results.</p>
                <p className="paragraph">Our website is constantly updated with new workout routines, equipment reviews, and expert advice, so be sure to check back often. Join our community of apartment workout enthusiasts today and start your journey to a healthier, happier lifestyle.</p>
            </div>
        </div>
    );
}

export default Home;
