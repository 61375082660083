import { useEffect, useState } from 'react';
import treadmill from '../../images/treadmill.jpeg'
import '../home/Home.css';
import './Treadmill.css'

const Treadmill = () => {
    const [date, setDate] = useState('')
    useEffect(() => {
        const d = new Date();
        d.setMonth(d.getMonth() - 2);
        d.setDate(d.getDate() - 2)
        setDate(d.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) )
    }, [])

    return (
        <div className="treadmill-container">
            <div className="home-text-container">
                <a
                    target="_blank"
                    className="outside-link"
                    rel="noopener noreferrer"
                    href="https://www.amazon.com/gp/product/B002FJZLJY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B002FJZLJY&linkCode=as2&tag=apartmentwork-20&linkId=cb5eabbddb285750b1b0fd31b29cfb04"
                >
                    <img src={treadmill} className="main-image treadmill-image" alt="treadmill" />
                </a>
                <p className="last-updated">Last updated on {date}</p>
                <div className="home-header-container">
                    <div className="home-divider">|</div>
                    <h1 className="home-header">Best Apartment Treadmill</h1>
                </div>
                <p className="paragraph">
                    The Best Treadmill for Small Spaces is the
                    <a
                        target="_blank"
                        className="outside-link"
                        rel="noopener noreferrer"
                        href="https://www.amazon.com/gp/product/B002FJZLJY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B002FJZLJY&linkCode=as2&tag=apartmentwork-20&linkId=cb5eabbddb285750b1b0fd31b29cfb04"
                    >
                        LifeSpan TR3000iT Folding Treadmill
                    </a>
                    . Now obviously, this particular piece of exercise equipment
                    may not be suited for a second story apartment 
                    (I mean, maybe it could, depending on how pro-health your downstairs neighbors are!).
                    But let's look over the obvious noise that is accompanied by trotting along on a treadmill,
                    and instead focus on the quality of the treadmill itself.
                </p>
                <p className="paragraph">The LifeSpan TR3000iT Folding Treadmill is a high-quality piece of fitness equipment that offers a variety of features to help you reach your fitness goals. This treadmill is designed with a powerful motor, a durable belt, and a variety of workout programs that make it suitable for both beginners and experienced runners.</p>
                <p className="paragraph">One of the most impressive features of this treadmill is its motor. With a 2.75 horsepower motor, it is capable of providing a top speed of 12 miles per hour, which is more than enough for most runners. The motor is also whisper-quiet, allowing you to enjoy your workout without disturbing others in your home.</p>
                <p className="paragraph">The treadmill belt is also noteworthy. It measures 20 x 56 inches, providing ample space for running or walking. The belt is made of high-quality materials that are both durable and comfortable to run on. Plus, the belt is reversible, which helps to extend its lifespan and reduce the need for maintenance.</p>
                <p className="paragraph">The TR3000iT also includes a variety of workout programs that can be customized to suit your needs. With 17 pre-set programs, you can choose from a range of workout types, including fat burning, cardio, and interval training. There are also two customizable programs, which allow you to create your own workouts based on your specific fitness goals.</p>
                <p className="paragraph">Other features of the TR3000iT include a heart rate monitor, a built-in USB charging port, and Bluetooth connectivity. The heart rate monitor is conveniently located on the handlebars, allowing you to track your heart rate throughout your workout. The USB charging port is a nice touch, allowing you to charge your phone or tablet while you exercise. And the Bluetooth connectivity lets you connect your treadmill to a variety of fitness apps and trackers, so you can keep track of your progress over time.</p>
                <p className="paragraph">
                    Overall, the LifeSpan TR3000iT Folding Treadmill is a great option for anyone looking for a high-quality, feature-rich treadmill. It is well-built, easy to use, and packed with features that can help you achieve your fitness goals. Whether you're a beginner or an experienced runner, this treadmill is a great choice that is sure to provide you with years of use.
                    <a
                        target="_blank"
                        className="outside-link"
                        rel="noopener noreferrer"
                        href="https://www.amazon.com/gp/product/B002FJZLJY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B002FJZLJY&linkCode=as2&tag=apartmentwork-20&linkId=cb5eabbddb285750b1b0fd31b29cfb04"
                    >
                        $1399, Amazon
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Treadmill;
