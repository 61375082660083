import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import './Navbar.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleNav = () => setIsOpen(!isOpen);

    return (
        <header className="navbar-container">
            <div className={`drawer-container ${isOpen && "drawer-container--isOpen"}`}>
                <div className="drawer-close-container">
                    <div className="drawer-close-header">Apartment Workouts</div>
                    <div className="drawer-close">
                        <span className="drawer-close-btn" onClick={toggleNav}>X</span>
                    </div>
                </div>
                <nav className="drawer-nav">
                    <div className="drawer-nav-link">
                        <Link to="/" className="main-link drawer-link" onClick={toggleNav}>HOME</Link>
                    </div>
                    <div className="drawer-nav-link">
                        <Link to="/quiet-workouts-for-your-apartment-or-home-gym" className="main-link drawer-link" onClick={toggleNav}>WORKOUTS</Link>
                    </div>
                    <div className="drawer-nav-link">
                        <Link to="/best-treadmill-for-your-apartment-or-home-gym" className="main-link drawer-link" onClick={toggleNav}>TREADMILLS</Link>
                    </div>
                    <div className="drawer-nav-link">
                        <Link to="/best-exercise-bike-for-your-apartment-or-home-gym" className="main-link drawer-link" onClick={toggleNav}>EXERCISE BIKES</Link>
                    </div>
                    <div className="drawer-nav-link">
                        <Link to="/best-power-tower-for-your-apartment-or-home-gym" className="main-link drawer-link" onClick={toggleNav}>POWER TOWERS</Link>
                    </div>
                    <div className="drawer-nav-link">
                        <Link to="/best-ab-wheel-for-your-apartment-or-home-gym" className="main-link drawer-link" onClick={toggleNav}>AB WHEELS</Link>
                    </div>
                    <div className="drawer-nav-link">
                        <Link to="/best-dumbbell-set-for-your-apartment-or-home-gym" className="main-link drawer-link" onClick={toggleNav}>DUMBBELL SETS</Link>
                    </div>
                </nav>
            </div>
            <div className="navbar-hamburger" onClick={toggleNav}>
                <FontAwesomeIcon icon={faBars} size="lg" />
            </div>
            <div className="navbar-header-container">
                <Link to="/" className="header-link">
                    <div className="navbar-header-icon">AW</div>
                    <h1 className="navbar-header">Apartment Workouts</h1>
                </Link>
            </div>
            <nav className="navbar-header-main">
                <div className="navbar-header-main-link"><Link to="/quiet-workouts-for-your-apartment-or-home-gym" className="main-link">WORKOUTS</Link></div>
                <div className="navbar-header-main-link"><Link to="/best-treadmill-for-your-apartment-or-home-gym" className="main-link">TREADMILLS</Link></div>
                <div className="navbar-header-main-link"><Link to="/best-exercise-bike-for-your-apartment-or-home-gym" className="main-link">EXERCISE BIKES</Link></div>
                <div className="navbar-header-main-link"><Link to="/best-power-tower-for-your-apartment-or-home-gym" className="main-link">POWER TOWERS</Link></div>
                <div className="navbar-header-main-link"><Link to="/best-ab-wheel-for-your-apartment-or-home-gym" className="main-link">AB WHEELS</Link></div>
                <div className="navbar-header-main-link"><Link to="/best-dumbbell-set-for-your-apartment-or-home-gym" className="main-link">DUMBBELL SETS</Link></div>
            </nav>
        </header>
    );
}

export default Navbar;
