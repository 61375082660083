import { useEffect, useState } from 'react';
import abwheel from '../../images/abwheel.jpeg'
import '../home/Home.css'
import './Wheel.css'

const Wheel = () => {
    const [date, setDate] = useState('')
    useEffect(() => {
        const d = new Date();
        d.setMonth(d.getMonth() - 2);
        d.setDate(d.getDate() - 22)
        setDate(d.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) )
    }, [])

    return (
        <div className="wheel-container">
            <div className="home-text-container">
                <a
                    href="https://www.amazon.com/gp/product/B00B1N0R6C/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B00B1N0R6C&amp;linkId=b2fc3dceaecdc49625ca5b0a86991e58"
                    target="_blank"
                    className="outside-link"
                    rel="noopener noreferrer"
                >
                    <img src={abwheel} className="main-image wheel-image" alt="ab wheel" />
                </a>
                <p className="last-updated">Last updated on {date}</p>
                <div className="home-header-container">
                    <div className="home-divider">|</div>
                    <h1 className="home-header">Best Ab Wheel For Six Pack Abs</h1>
                </div>
                <p className="paragraph">
                    The Best Ab Wheel for Small Spaces is the
                    <a
                        href="https://www.amazon.com/gp/product/B00B1N0R6C/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B00B1N0R6C&amp;linkId=b2fc3dceaecdc49625ca5b0a86991e58"
                        target="_blank"
                        className="outside-link"
                        rel="noopener noreferrer"
                    >
                        Perfect Fitness Ab Carver Pro
                    </a>
                    . At first I was skeptical about this product because
                    it seemed like it could be another gimmick or waste of money.
                    Boy was I wrong! I've been doing ab workouts in my apartment
                    without an ab wheel for several months and find it hard to make
                    my abs sore. After one use of this product (only 15-20 rolls at first)
                    my abs were sore for 2 days!
                </p>
                <p className="paragraph">The Perfect Fitness Ab Carver Pro is an innovative piece of fitness equipment designed to help you tone and sculpt your abs and core muscles. This ab roller is built with high-quality materials and features a variety of unique design elements that make it an excellent choice for anyone looking to improve their fitness and physique.</p>
                <p className="paragraph">One of the most impressive features of the Ab Carver Pro is its ergonomic design. The roller features a wide, sturdy wheel that provides a stable platform for your exercises, as well as ergonomic handles that provide a comfortable and secure grip. The handles are also angled, which helps to engage your arm and shoulder muscles as you roll forward and back.</p>
                <p className="paragraph">Another great feature of the Ab Carver Pro is its resistance mechanism. The roller includes a carbon steel spring that provides resistance as you roll forward, helping to engage your abs and core muscles even more effectively. The resistance mechanism is adjustable, allowing you to increase or decrease the difficulty of your workout as needed.</p>
                <p className="paragraph">The Ab Carver Pro also features a durable construction. Made from high-quality materials, the roller is designed to withstand even the most intense workouts, and its non-slip rubber treads provide a secure grip on any surface. Additionally, the roller includes a knee pad to protect your knees as you exercise.</p>
                <p className="paragraph">Other features of the Ab Carver Pro include its compact size and easy assembly. The roller can be easily disassembled and stored when not in use, making it an ideal choice for anyone with limited storage space.</p>
                <p className="paragraph">
                    Overall, the Perfect Fitness Ab Carver Pro is an excellent choice for anyone looking to improve their core strength and tone their abs. With its ergonomic design, resistance mechanism, and durable construction, this roller is sure to help you achieve your fitness goals and improve your overall health and wellness.
                    <a
                        href="https://www.amazon.com/gp/product/B00B1N0R6C/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B00B1N0R6C&amp;linkId=b2fc3dceaecdc49625ca5b0a86991e58"
                        target="_blank"
                        className="outside-link"
                        rel="noopener noreferrer"
                    >
                        $39, Amazon
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Wheel;
