import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Home, Dumbbell, Bike, Tower, Treadmill, Wheel, Navbar, Workouts, BadRoute } from './components';

const ScrollToTop = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
  
    return <>{children}</>;
}

const App = () => {
    return (
        <div className="app-container">
            <Router>
                <ScrollToTop>
                    <Navbar />
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="best-dumbbell-set-for-your-apartment-or-home-gym" element={<Dumbbell />} />
                        <Route exact path="best-exercise-bike-for-your-apartment-or-home-gym" element={<Bike />} />
                        <Route exact path="best-power-tower-for-your-apartment-or-home-gym" element={<Tower />} />
                        <Route exact path="best-treadmill-for-your-apartment-or-home-gym" element={<Treadmill />} />
                        <Route exact path="best-ab-wheel-for-your-apartment-or-home-gym" element={<Wheel />} />
                        <Route exact path="quiet-workouts-for-your-apartment-or-home-gym" element={<Workouts />} />
                        <Route path="*" element={<BadRoute />} />
                    </Routes>
                </ScrollToTop>
            </Router>
        </div>
    )
}

export default App;