import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import workout from '../../images/workout.jpeg'
import '../home/Home.css'
import './Workouts.css';

const Workouts = () => {
    const [date, setDate] = useState('')
    useEffect(() => {
        const d = new Date();
        d.setDate(d.getDate() - 6)
        setDate(d.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) )
    }, [])

    return (
        <div className="workouts-container">
            <div className="home-text-container">
                <img src={workout} className="workout-image" alt="push up" />
                <p className="last-updated">Last updated on {date}</p>
                <div className="home-header-container">
                    <div className="home-divider">|</div>
                    <h1 className="home-header">5 Quiet Workouts That Won't Annoy Your Neighbors</h1>
                </div>
                <p className="paragraph">
                    When you think about working out in your apartment,
                    you may be a little worried that you could disturb your neighbors.
                    The good news is there are TONS of workouts that you can do from
                    the comfort of your apartment, with making little-to-no noise!
                    Some exercises utilize creative use of furniture or your own bodywieght, while others require
                    at-home gym equipment
                    (see our<Link to="/best-exercise-bike-for-your-apartment-or-home-gym" className="outside-link">equipment reviews</Link> for more info).
                </p>
                <h3 className="number-header first-number-header">1. Push Ups</h3>
                <div className="paragraph">
                    The Push Up is one of the most fundamental body weight exercises,
                    that requires a lot of different muscles to be used at the same time.
                    For this reason, it's one of the best workouts that you can do without equipment,
                    which makes it a great option for your apartment workout.
                    <div className="target-muscles-container">
                        <span className="target-muscles">Target Muscles: </span>
                        Chest, Triceps, Shoulders
                    </div>
                </div>
                <div className="video-container">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/IODxDxX7oi4"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
                </div>
                <h3 className="number-header">2. Bicep Curls</h3>
                <div className="paragraph">
                    Bicep Curls, as the name suggests, predominately works the bicep -
                    the front muscle on your upper arm. When starting to tone and
                    workout your arms, the bicep curl will help add muscle definition
                    and strength, which will aid in your other workouts. 
                    <div className="target-muscles-container">
                        <span className="target-muscles">Target Muscles: </span>
                        Biceps
                    </div>
                </div>
                <div className="video-container">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Nkl8WnH6tDU"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
                </div>
                <h3 className="number-header">3. Bodyweight Squats</h3>
                <div className="paragraph">
                    Bodyweight Squats tone and stengthen your leg muscles by using your own bodyweight.
                    While this move is simple to perform, it is important to do it properly.
                    Not only does proper execution help you avoid injuries (knee most commonly),
                    but will also ensure you are maximizing your movements.
                    Once the bodyweight squat is mastered, you can start to add weights into the mix
                    to furthur your strength and muscle growth.
                    <div className="target-muscles-container">
                        <span className="target-muscles">Target Muscles: </span>
                        Quads, Glutes, Hamstrings, Core, Lower Back
                    </div>
                </div>
                <div className="video-container">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/cB0cOX7gePg"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
                </div>
                <h3 className="number-header">4. Ab Wheel</h3>
                <div className="paragraph">
                    Whether summertime is right around the corner or you are just ready to start
                    toning your stomache muscles more, the best all-around ab exercise is the ab wheel.
                    It's a great way to target various abdominal muscles - the upper and lower abs,
                    as well as the obliques. Ab wheels can be tough to use at first, but will strengthen
                    not only your abs, but lower back as well.
                    <div className="target-muscles-container">
                        <span className="target-muscles">Target Muscles: </span>
                        Core, Lower Back
                    </div>
                </div>
                <div className="video-container">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/xMqTWLDTjcQ"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
                </div>
                <h3 className="number-header">5. Pull Ups</h3>
                <div className="paragraph">
                    If you are just starting your workout journey, then the pull up is likely going to be the
                    most difficult exercise on this list. Why? It requires you to be able to pull your entire body weight
                    above a bar. It is precisely because of its difficulty that the pull up should be included
                    in your workout routine. It strengthens multiple muscle groups and has many variations -
                    including ones that can assist if you're just starting out.
                    <div className="target-muscles-container">
                        <span className="target-muscles">Target Muscles: </span>
                        Lats, Biceps, Delts, Core
                    </div>
                </div>
                <div className="video-container">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/eGo4IYlbE5g"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
                </div>
            </div>
        </div>
    );
}

export default Workouts;
