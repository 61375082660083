import { Link } from 'react-router-dom';
import babylift from '../../images/babylift.jpeg'
import '../home/Home.css'
import './BadRoute.css';

const BadRoute = () => {
    return (
        <div className="badroute-container">
            <img src={babylift} alt="baby lifting weight" className="badroute-image" />
            <p className="badroute-header">Whoops, looks like this page doesn't exist</p>
            <Link to="/" className="outside-link">Take Me Home</Link>
        </div>
    );
};

export default BadRoute;